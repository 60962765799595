$(document).ready(function(){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

/***********General functions*****************/
var DATE_FORMAT = "DD-MM-YYYY";

$('.btn-delete').click(function (e) {
    var btn = $(this);
    e.preventDefault();
    swal({
        title: "Seguro que desea eliminar?",
        text: "Porfavor confirma la acción",
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "No, cancelar",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Si, eliminar",
        closeOnConfirm: true
    }, function () {
        btn.closest("form.delete").submit();
    });
});

/************Set default values************/
$(function() {
    $.fn.datepicker.defaults.language = 'es';
});
/**************Translations****************/
$.fn.datepicker.dates['es'] = {
    days: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado", "Domingo"],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
    daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa", "Do"],
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
};

/**********Double Scroll***********/
function listenDoubleScroll(){
    $('input[class=show_scroll]').on('ifChanged', function(event){
        if( event.target.checked ){
            if( $('.doubleScroll-scroll-wrapper').length > 0 ){
                $('.doubleScroll-scroll-wrapper').css("display", 'block');
            } else {
                $('.double-scroll').doubleScroll({resetOnWindowResize: true});    
            }
            
            Cookies.set('show_scroll', '1' );
        } else{
            $('.doubleScroll-scroll-wrapper').css("display", 'none');
            Cookies.set('show_scroll', '0' )
        }
    });
}

/***********DropZone***************/
function initDropZone(target, idModel){
    Dropzone.confirm = function(question, accepted, rejected) {

        return swal({
            title: "Seguro que desea eliminar la imagen?",
            text: "Porfavor confirma la acción",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "No, cancelar",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Si, eliminar",
            closeOnConfirm: true
        }, function () {
            accepted();
        });

        rejected();

    };
    var photo_counter = 0;
    Dropzone.options.realDropzone = {

        uploadMultiple: false,
        parallelUploads: 100,
        //maxFilesize: 8,
        previewsContainer: '#dropzonePreview',
        previewTemplate: document.querySelector('#preview-template').innerHTML,
        addRemoveLinks: true,
        dictRemoveFile: 'Eliminar',
        dictRemoveFileConfirmation: true,
        dictFileTooBig: 'La imagen es muy grande.',

    // The setting up of the dropzone
    init:function() {

        // Add server images
        var myDropzone = this;

        $.get(target + idModel + '/uploaded-images', function(data) {

            $.each(data.images, function (key, value) {
                
                var file = {name: value.original};
                myDropzone.options.addedfile.call(myDropzone, file);
                myDropzone.options.thumbnail.call(myDropzone, file, value.server);
                myDropzone.emit("complete", file);
                photo_counter++;
                $("#photoCounter").text( "(" + photo_counter + ")");

            });
        });

        this.on("removedfile", function(file) {

            $.ajax({
                type: 'POST',
                url: target + idModel + '/image-delete',
                data: {id: file.name, _token: $('#csrf-token').val()},
                dataType: 'html',
                success: function(data){
                    var rep = JSON.parse(data);
                    if(rep.code == 200){
                        photo_counter--;
                        $("#photoCounter").text( "(" + photo_counter + ")");
                        return true;
                    } else {
                        alert(rep.msg)
                    }
                    return false;
                }
            });

        } );
    },
    error: function(file, response) {
        if($.type(response) === "string")
            var message = response; //dropzone sends it's own error messages in string
        else
            var message = response.message;
        file.previewElement.classList.add("dz-error");
        _ref = file.previewElement.querySelectorAll("[data-dz-errormessage]");
        _results = [];
        for (_i = 0, _len = _ref.length; _i < _len; _i++) {
            node = _ref[_i];
            _results.push(node.textContent = message);
        }
        return _results;
    },
    success: function(file,done) {
        photo_counter++;
        $("#photoCounter").text( "(" + photo_counter + ")");
    }
}
}